import { TASK_DATE_ITEM_FORMAT, TASK_SIDEBAR_ITEM_FORMAT, TASK_SIDEBAR_ORDER } from 'constants/employee/task';
import { RESPONSE_TYPE_MAP } from 'constants/response';
import { TIME_FORMAT } from 'constants/time';
import _ from 'lodash';
import { TAdminCalendar, TSaltOpsFormType, TTaskItemDateTimeType } from 'models';
import {
  TEmployeeTask,
  TEmployeeTaskItem,
  TEmployeeTaskResponse,
  TEmployeeTasks,
  TTaskStatusGroup,
} from 'models/employee/task.model';
import moment from 'moment';
import { formatDate } from 'utils/date';
import { toNumber } from 'utils/number';
import * as yup from 'yup';

export function formatTasks(tasks?: TEmployeeTasks) {
  if (_.isEmpty(tasks)) return [];

  // tmp hide todo from the list
  _.unset(tasks, 'todo');

  return _.chain(tasks)
    .map((task: TEmployeeTask, taskGroup: TTaskStatusGroup) => {
      const order = TASK_SIDEBAR_ORDER[taskGroup];
      return { ...task, taskGroup, order };
    })
    .orderBy('order')
    .value() as any;
}

export function formatTaskItemDate(date?: string) {
  return formatDate(date, TASK_SIDEBAR_ITEM_FORMAT);
}

export function generateTaskAnswerPayload(answer: any, additionalPayload?: any) {
  const mediaAttributes = answer?.mediaAttributes;

  // media
  if (mediaAttributes) {
    return {
      response: {
        mediaAttributes,
        ...additionalPayload,
      },
    };
  }

  // QR, Bar code
  if (answer?.code || answer?.comment) {
    return {
      response: {
        answer: answer?.code,
        comment: answer?.comment,
        ...additionalPayload,
      },
    };
  }

  // others
  return {
    response: {
      answer,
      ...additionalPayload,
    },
  };
}

export function getDateTimeType(dateTimeType?: any) {
  if (!dateTimeType) {
    return {};
  }

  const isDateTimeType = dateTimeType === 'datetime';
  const isDateType = dateTimeType === 'date';
  const isTimeType = dateTimeType === 'time';
  return { isDateTimeType, isDateType, isTimeType };
}

// input: 2023-05-07T13:10:30.088Z
// output: 07-05-2023
export function toAnswerDate(date?: string | Date | null) {
  return formatDate(date, TASK_DATE_ITEM_FORMAT);
}

// input: 2023-05-07T13:10:30.088Z
// output:
//   noTimeZone = true:  11:11
//   noTimeZone = false: 11:11+07:00
export function toAnswerTime(date?: string | Date | null, noTimeZone?: boolean) {
  return formatDate(date, TIME_FORMAT, !noTimeZone);
}

// input: 2023-05-07T13:10:30.088Z
// output:
//   isZeroHour = true:  07-05-2023 00:00+07:00
//   isZeroHour = false: 07-05-2023 11:11+07:00
export function toAnswerDateTime(date?: string | Date | null, isZeroHour?: boolean) {
  return isZeroHour
    ? formatDate(date, `${TASK_DATE_ITEM_FORMAT} 00:00Z`)
    : formatDate(date, `${TASK_DATE_ITEM_FORMAT} ${TIME_FORMAT}Z`);
}

// input:
//   isZeroHour = true:  07-05-2023
//   isZeroHour = false: 07-05-2023 11:11+07:00
// output: 2023-05-07T13:10:30.088Z
export function formatAnswerDateTime(date?: string, outputFormat: string = 'YYYY-MM-DD HH:mmZ') {
  if (!date) return '';

  return moment(date, 'DD-MM-YYYY HH:mm').local(true).format(outputFormat);
}

export function formatAnswerDate(date?: string, outputFormat: string = 'YYYY-MM-DD HH:mmZ') {
  if (!date) return '';

  const formattedDate = moment(date, 'DD-MM-YYYY');
  return formattedDate?.local(true).format(outputFormat);
}

// input: 11:11+07:00
// output: 2023-05-07T13:10:30.088Z
export function formatAnswerTime(date?: string, outputFormat: string = 'YYYY-MM-DD HH:mmZ') {
  if (!date) return '';

  // for old answer
  const oldFormattedTime = moment(date, 'DD-MM-YYYY HH:mm');
  if (oldFormattedTime.isValid()) {
    return oldFormattedTime.local(true).format(outputFormat);
  }

  // for new answer
  const formattedTime = moment(date, 'HH:mm');
  if (formattedTime.isValid()) {
    return formattedTime.local(true).format(outputFormat);
  }

  return '';
}

export const getDefaultDateTimeValue = (dateTimeType: string, answer: string | null) => {
  const { isDateTimeType, isDateType, isTimeType } = getDateTimeType(dateTimeType);
  const nextAnswer = answer || '';
  const output: any = { date: null, time: null };

  if (isDateTimeType) {
    const [date, time] = nextAnswer.split(' ');
    if (!date || !time) return output;

    const formattedDateTime = formatAnswerDateTime(nextAnswer);
    output.date = formattedDateTime ? new Date(formattedDateTime) : null;
    output.time = formattedDateTime ? new Date(formattedDateTime) : null;
  }

  if (isDateType) {
    const formattedDateTime = formatAnswerDate(nextAnswer);
    output.date = formattedDateTime ? new Date(formattedDateTime) : null;
  }

  if (isTimeType) {
    const formattedDateTime = formatAnswerTime(nextAnswer);
    output.time = formattedDateTime ? new Date(formattedDateTime) : null;
  }

  return output;
};

export const generateFormValidations = (responses: TEmployeeTaskResponse[], list?: TEmployeeTaskItem) => {
  const isSweepList = !!list?.sweep;
  const validations = responses
    .map((res, index) => {
      const isNotNA = res.status !== 'not_applicable';
      const required = res?.item?.required && isNotNA && res.displayable;
      const isSection = res?.type === RESPONSE_TYPE_MAP.SECTION;

      //  check Answer whether valid or not
      let validAnswer = res?.answer;

      if (res?.type === RESPONSE_TYPE_MAP.SALT_OPS_FORM) {
        validAnswer = res?.answer?.id;
      }

      if ([RESPONSE_TYPE_MAP.QR_CODE, RESPONSE_TYPE_MAP.BARCODE].includes(res?.type)) {
        validAnswer = !!res?.answer?.code || !!res?.answer?.comment;
      }

      if ([RESPONSE_TYPE_MAP.MULTIPLE_CHOICE, RESPONSE_TYPE_MAP.AREA, RESPONSE_TYPE_MAP.EMPLOYEE].includes(res?.type)) {
        validAnswer = Array.isArray(res?.answer) && res?.answer?.length > 0;
      }

      if (res?.type === RESPONSE_TYPE_MAP.MEDIA) {
        validAnswer = res?.answer?.id && res.answer?.files?.length > 0;
      }

      if (res?.type === RESPONSE_TYPE_MAP.NUMERIC) {
        const answerNumber = toNumber(res?.answer);
        const minNumber = toNumber(res?.item?.minNumber);
        const maxNumber = toNumber(res?.item?.maxNumber);
        const minLabel = res?.item?.minLabel;
        const maxLabel = res?.item?.maxLabel;

        return {
          [`responses.${index}`]: yup.object({
            answer: yup
              .number()
              .when({
                is: () => !isSweepList && !_.isNumber(answerNumber) && required,
                then: schema => schema.required('This task item is required.'),
                otherwise: schema => schema.nullable(),
              })
              .when({
                is: () => !isSweepList && _.isNumber(minNumber) && _.isNumber(answerNumber) && answerNumber < minNumber,
                then: schema => schema.required(minLabel || `The minimum value is ${minNumber}`),
                otherwise: schema => schema.nullable(),
              })
              .when({
                is: () => !isSweepList && _.isNumber(maxNumber) && _.isNumber(answerNumber) && answerNumber > maxNumber,
                then: schema => schema.required(maxLabel || `The maximum value is ${maxNumber}`),
                otherwise: schema => schema.nullable(),
              }),
          }),
        } as any;
      }

      return {
        [`responses.${index}`]: yup.object({
          answer: yup.string().when({
            is: () => !isSweepList && !isSection && !validAnswer && required,
            then: schema => schema.required('This task item is required.'),
            otherwise: schema => schema.nullable(),
          }),
        }),
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return validations;
};

export const generateCallbackUrl = (listId: number, responseId: number, locationId: number) => {
  return `${window.location.origin}${window.location.pathname}?list-id=${listId}&response-id=${responseId}&location-id=${locationId}`;
};

export const getLinkFormType = (formType: TSaltOpsFormType) => {
  const typeFormMap = new Map<TSaltOpsFormType, string>();
  typeFormMap.set('hazard', 'hazard-form');
  typeFormMap.set('headcount', 'head-count-form');
  typeFormMap.set('incident', 'incident-form');
  typeFormMap.set('maintenance', 'maintenance-form');
  typeFormMap.set('water_chemistry', 'water-chemistry');
  typeFormMap.set('pooltest', 'pool-test-form');

  return typeFormMap.get(formType) || '';
};

export const generateReturnUrl = ({
  listId,
  responseId,
  locationId,
  formType,
  saltUrl,
}: {
  listId: number;
  responseId: number;
  locationId: number;
  formType: TSaltOpsFormType;
  saltUrl: string;
}) => {
  const callbackUrl = generateCallbackUrl(listId, responseId, locationId);
  const linkedFormType = getLinkFormType(formType);
  return `${saltUrl}/sso_login?return_url=/operation/${linkedFormType}&callback_url=${callbackUrl}`;
};

export const isCompletedRequiredTasks = (responses: TEmployeeTaskResponse[]) => {
  const requiredTasks = _.filter(
    responses,
    task => task.type !== RESPONSE_TYPE_MAP.SECTION && !!task.displayable && !!task.item?.required,
  );
  if (!requiredTasks) return true;

  const completedTasks = _.filter(requiredTasks, task => ['completed', 'overdue'].includes(task.status));
  if (!completedTasks) return false;

  return requiredTasks?.length === completedTasks?.length;
};

export const formatDateTimeAnswer = (dateTimeType: TTaskItemDateTimeType, answer: string) => {
  let nextAnswer = _.cloneDeep(answer);

  if (dateTimeType === 'time') {
    nextAnswer = formatAnswerTime(nextAnswer, 'HH:mm');
  } else if (dateTimeType === 'date') {
    nextAnswer = formatAnswerDate(nextAnswer, 'DD-MM-YYYY');
  } else if (dateTimeType === 'datetime') {
    nextAnswer = formatAnswerDateTime(nextAnswer, 'DD-MM-YYYY HH:mm');
  }

  return nextAnswer;
};

const getRoleName = ({ role, roles }: { role: TAdminCalendar['role']; roles: TAdminCalendar['roles'] }) => {
  if (_.isEmpty(roles)) return role?.name || '';
  const roleNames = _.map(roles, 'name');
  return roleNames?.join(', ');
};

export const getDisplayRoleOrUserName = ({ individual, user, role, roles }: TAdminCalendar): string => {
  const roleName = getRoleName({ role, roles });
  if (!individual) return roleName;
  if (_.isEmpty(user)) return `Individual ${roleName} Lists`;
  return user?.name;
};
